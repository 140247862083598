.swiper {
  width: 100%;
  height: 100%;
  
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: none;
  

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-button-next,
.swiper-button-prev {
  background-color: #141d30; /* لون الخلفية */
  color: white; /* لون النص */
  border-radius: 15px; /* تدوير الزوايا لجعل الزر مستدير */
  padding: 8px; /* هامش داخلي */
  cursor: pointer; /* إظهار السهم */
  transition: background-color 0.3s ease; /* تحول التدريجي للون عند التحويل */
  width: 50px; /* عرض الزر */
  height: 50px; /* ارتفاع الزر */
  display: flex; /* عرض العنصر كمركز */
  justify-content: center; /* توسيط النص أفقيًا */
  align-items: center; /* توسيط النص رأسيًا */
}

.swiper-icon-prev {
  font-size: 15px;
}


